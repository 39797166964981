.App {
  text-align: initial;
}

.form-padding {
  padding-bottom: 20px;
}

.authenticator-wrap {
  overflow: hidden;
  display: block;
}
.authenticator-wrap a {
  color: #4283eb;
  font-size: 14px;
}
.authenticator-wrap [class^='Hint__hint___'] {
  font-size: 14px;
}

.authenticator-wrap button {
  background-color: #4283eb !important;
}
.authenticator-wrap button:hover {
  background-color: #1866e2 !important;
}

div.appErrorBackDrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 150px;
  background-color: rgba(55, 55, 55, 0.8);
}
div.appErrorBox {
  max-width: 400px;
  background: #fff;
  padding: 30px 10px;

  box-shadow: 0 0 20px #0000005c;
  overflow: hidden;

  margin: 20px auto;
}

div.appErrorBox h1 {
  margin: 10px;
  margin-bottom: 25px;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-InProgress {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 46%;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header-spacing {
  overflow: hidden;
  position: relative;
  height: 120px;
}

@media only screen and (max-width: 400px) {
  .header-spacing {
    height: 130px;
  }
}
